import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleWrapper } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const FlowWrapper = styled.div`
  padding: 50px 30px;

  /** Common Css **/
  ${SharedCss}

  /* PC */
  @media (min-width: 1024px) {
    display: flex;
    max-width: 1024px;
    padding: 160px 0;
    margin: 0 auto;
  }
`;

const FlowTitle = styled(TitleWrapper)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      width: 25%;
      text-align: left;
    }
  }
`;

const FlowMainTitle = styled(MainTitle)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      text-align: left;
    }
  }
`;

const FlowList = styled.ol`
  body & {
    position: relative;
    border-left: 2px solid #0093bb;

    &::after {
      position: absolute;
      bottom: -2px;
      left: -9px;
      display: block;
      width: 0;
      height: 0;
      content: '';
      border-color: #0093bb transparent transparent transparent;
      border-style: solid;
      border-width: 10px 8.5px 0 8.5px;
    }

    /* PC */
    @media (min-width: 1024px) {
      width: 85%;
      padding-left: 30px;
    }
  }
`;

const FlowListItem = styled.li`
  body & {
    position: relative;
    margin-bottom: 50px;
    margin-left: 20px;

    &::after {
      position: absolute;
      bottom: -30px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      margin-left: -70px;
      content: '';
      border-color: #03c1e3 transparent transparent transparent;
      border-style: solid;
      border-width: 20px 70px 0 70px;
    }

    &:last-of-type::after {
      display: none;
    }

    /* List / Item / Title - Color */

    &:nth-child(1) .Flow__ListItemTitle {
      background: #b2deea;
    }

    &:nth-child(2) .Flow__ListItemTitle {
      background: #7fc9dd;
    }

    &:nth-child(3) .Flow__ListItemTitle {
      background: #33a8c8;
    }

    &:nth-child(4) .Flow__ListItemTitle {
      background: #0093bb;
    }

    /* PC */
    @media (min-width: 1024px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:last-of-type {
        margin-bottom: 0;
      }

      &::after {
        bottom: -30px;
        left: 180px;
        border-width: 15px 50px 0 50px;
      }

      &:nth-child(3) .Flow__ListItemTitle {
        height: 90px;
        padding-top: 15px;
        line-height: 1.4;
      }

      &:nth-child(3) .Flow__ListItemTitle span {
        top: 42px;
      }
    }
  }
`;

const FlowListItemTitle = styled.h3`
  body & {
    position: relative;
    padding: 10px 0;
    color: #fff;
    text-align: center;

    span {
      position: absolute;
      top: 50%;
      left: 20px;
      margin-top: -13px;
    }

    /* PC */
    @media (min-width: 1024px) {
      width: 44%;
      padding: 0;
      line-height: 90px;

      span {
        top: 12px;
      }
    }
  }
`;

const FlowListItemDetail = styled.p`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      width: 52%;
      margin-top: 0;
      font-size: 16px;
    }
  }
`;

const FlowNumber = styled.span`
  body & {
    font-family: 'Mukta Vaani', sans-serif;
    font-weight: normal;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }
`;

const DATA_QUERY = graphql`
  query FlowItemsData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-flow" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            mainTitle
            flowItems {
              stepNo
              stepName
              detail
            }
          }
        }
      }
    }
  }
`;

/**
 * FLOW Item
 * @param item
 * @constructor
 */
function FlowItem(item: GatsbyTypes.MarkdownRemarkFrontmatterFlowItems) {
  return (
    <>
      <FlowListItem>
        <FlowListItemTitle className={'Flow__ListItemTitle'}>
          <FlowNumber>{item.stepNo}</FlowNumber>
          <Nl2Br text={item.stepName} />
        </FlowListItemTitle>
        <FlowListItemDetail>
          <Nl2Br text={item.detail} />
        </FlowListItemDetail>
      </FlowListItem>
    </>
  );
}

const Flow: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.FlowItemsDataQuery>(
    DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const flowItems = frontmatter?.flowItems;

  return (
    <FlowWrapper>
      <FlowTitle>
        <FlowMainTitle>{frontmatter?.mainTitle}</FlowMainTitle>
      </FlowTitle>
      <FlowList>
        {flowItems?.map((item, index) => (
          <FlowItem
            key={'flow-' + index}
            stepNo={item?.stepNo}
            stepName={item?.stepName}
            detail={item?.detail}
          />
        ))}
      </FlowList>
    </FlowWrapper>
  );
};

export default Flow;
